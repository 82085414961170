import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { TextBlock, RectShape } from "react-placeholder/lib/placeholders";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactMarkdown from "react-markdown";
import { useGetProductQuery,useGetRelatedProductQuery } from "../services/productsApi";
import RelatedProducts from "../components/relatedProducts";
import { useSelector, useDispatch } from "react-redux";
import { addProduct } from "../services/enquirySlice";

const ProductDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const enquiryList = useSelector((state) => state.enquiryProduct.products);
  const { id } = useParams();
  const [productId, setProductId] = useState("");
  const [productDetail, setProductDetail] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [readMoreDesc, setReadMoreDesc] = useState(true);
  const [showReadMore, setShowReadMore] = useState(false);
  const { data: relProd =[] } = useGetRelatedProductQuery(productId);
  const { data: prod } = useGetProductQuery(productId);

  useEffect(() => {
    if (id) {
      const mySubString = id.substring(
        id.indexOf("=") + 1,
        id.lastIndexOf(",")
      );
      setProductId(mySubString);
    }
    window.scrollTo(0, 0);
  }, [id]);


  useEffect(() => {
    if (prod) {
        setProductDetail(prod);
        document.title = `Agni | ${prod.name}`;
    }
  }, [prod]);

  useEffect(() => {
    if (productDetail) {
      if (productDetail.description && productDetail.description.length > 132) {
        setShowReadMore(true);
      }
    }
  }, [productDetail]);

  return productDetail !== null ? (
    <div className="pt-16 lg:pt-24">
      {/*
       * Breadcrumbs
       */}
      <nav className="container">
        <ul className="ag-breadcrumbs">
          <li className="ag-breadcrumbs__item">
            <div className="ag-breadcrumbs__link">Products</div>
          </li>
          <li className="ag-breadcrumbs__item">
            {productDetail != null && productDetail.category ? (
              <Link
                to={`/products/id=${productDetail.category.id},name=${productDetail.category.name}`}
                className="ag-breadcrumbs__link"
              >
                {productDetail.category.name}
              </Link>
            ) : (
              <div></div>
            )}
          </li>
          <li className="ag-breadcrumbs__item">
            <div className="ag-breadcrumbs__link ag-breadcrumbs__link--active">
              {productDetail.name}
            </div>
          </li>
        </ul>
      </nav>

      {/*
       * Product Hero
       */}
      <section className="ag-section--small">
        <div className="container grid gap-y-8 lg:grid-cols-12 gap-x-14">
          <div className="lg:col-span-6">
            <figure className="ag-product__image-container">
              {productDetail.image && (
                <img
                  className="ag-product__image"
                  src={"https://api.eversafe.com.np" + productDetail.image.url}
                  alt="fire extinguisher"
                />
              )}
            </figure>
          </div>
          <div className="lg:col-span-6 grid md:grid-cols-12 md:gap-x-5 lg:flex justify-center">
            <div className="ag-product__content md:col-start-2 md:col-span-10">
              <h4 className="ag-sub-title mb-1">{productDetail.brand}</h4>
              <h2 className="ag-heading--two text-stormGray font-medium mb-4">
                {productDetail.name}
              </h2>
              <div className="ag-text mb-8">
               {productDetail.description && <ReactMarkdown
                  children={
                    readMoreDesc 
                      ? productDetail.description.substring(0, 132) + "..."
                      : productDetail.description
                  }
                />}
                {showReadMore && (
                  <span>
                    <button
                      className="ag-link--more mt-2 inline-block"
                      onClick={() => {
                        setReadMoreDesc(!readMoreDesc);
                      }}
                    >
                      {readMoreDesc ? "Read more" : "Read less"}
                    </button>
                  </span>
                )}
              </div>
              <div className="flex flex-wrap gap-y-4 gap-x-5 mb-8">
                {productDetail != null &&
                productDetail.catalog &&
                productDetail.catalog.length > 0 ? (
                  <a
                    href={
                      "https://api.eversafe.com.np" +
                      productDetail.catalog[0].url
                    }
                    rel="noreferrer"
                    target="_blank"
                    className="btn-secondary"
                  >
                    View Catalogue
                  </a>
                ) : (
                  <></>
                )}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsAdded(true);
                    const index = enquiryList.findIndex(
                      (data) => data.id === productDetail.id
                    );
                    if (index < 0) {
                      dispatch(addProduct(productDetail));
                    }
                    setInterval(() => {
                      setIsAdded(false);
                    }, 2000);
                  }}
                  className="btn-primary"
                >
                  {isAdded ? "Added to Enquiry List" : "Add to Enquiry List"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
       * Technical Specification
       */}
      <section className="ag-section">
        <div className="container">
          <h3 className="ag-title--small mb-8">Technical Specification</h3>
        </div>

        <div className="container">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full overflow-hidden">
              {/* <ReactMarkdown children={productDetail.specification}  /> */}
              <div
                className="ag-table__container"
                dangerouslySetInnerHTML={{
                  __html: productDetail.specification,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <RelatedProducts relProd={relProd}/>
    </div>
  ) : (
    <div>
      <section className="ag-section--small">
        <div style={{ height: "150px" }}></div>
        <div className="container grid gap-y-8 lg:grid-cols-12 gap-x-14">
          <div className="lg:col-span-6">
            <RectShape color="#b8bab9" />
          </div>
          <div className="lg:col-span-6 flex items-center">
            <TextBlock rows={20} color="#b8bab9" />
          </div>
        </div>
      </section>
    </div>
  );
};
export default ProductDetail;
