import {configureStore} from '@reduxjs/toolkit';
import { categoryApi } from './services/categoryApi';
import { productsApi } from './services/productsApi';
import enquiryReducer from './services/enquirySlice';

export default configureStore({
    reducer:{
        [categoryApi.reducerPath]:categoryApi.reducer,
        [productsApi.reducerPath]:productsApi.reducer,
        enquiryProduct: enquiryReducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(categoryApi.middleware, productsApi.middleware),
});