import { Fragment, useState, useEffect, useCallback, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, AnnotationIcon } from "@heroicons/react/outline";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../assets/logo.png";
// import LogoIcon from '../assets/logo_icon.png';
import {
  removeAll,
  removeProduct,
  initializeProduct,
} from "../services/enquirySlice";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const enquiryList = useSelector((state) => state.enquiryProduct.products);

  const [isOpenEnq, setIsOpenEnq] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [selectedNav, setSelectedNav] = useState("home");
  const [y, setY] = useState(window.scrollY);

  const headerRef = useRef(null);

  const handleNavigation = useCallback((e) => {
    const window = e.currentTarget;
    setY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedNav("home");
      document.title = "Agni | Home";
    } else if (location.pathname === "/about") {
      setSelectedNav("about");
      document.title = "Agni | About Us";
    } else if (location.pathname === "/contact") {
      setSelectedNav("contact");
      document.title = "Agni | Contact Us";
    } else if (location.pathname.includes("products")) {
      setSelectedNav("products");
      document.title = "Agni | Products";
    } else if (location.pathname.includes("product")) {
      setSelectedNav("product");
      document.title = "Agni | Product";
    }
  }, [location]);

  useEffect(() => {
    let enquiryList = localStorage.getItem("enquiryList");
    if (enquiryList) {
      dispatch(initializeProduct(JSON.parse(enquiryList)));
    }
  }, [dispatch]);

  return (
    <>
      <Transition.Root show={isOpenEnq} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex" onClose={setIsOpenEnq}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="-translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="-translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ag-header__enq">
              <div className="h-16 lg:h-20 px-8 flex items-center justify-between border-solid border-b border-gray-200">
                <h3 className="ag-header__enq__title">Enquiries</h3>
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setIsOpenEnq(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="grid gap-y-6 mt-8 px-8">
                {enquiryList.length > 0 ? (
                  enquiryList.map((item) => (
                    <div className="ag-card--horizontal" key={item.id}>
                      <figure className="ag-card__image-container">
                        <img
                          src={"https://api.eversafe.com.np" + item.image.url}
                          alt={item.name}
                          className="ag-card__image"
                        />
                      </figure>
                      <div className="flex-1">
                        <h3 className="ag-card__title">{item.name}</h3>
                      </div>
                      <Link
                        to={`/product/id=${item.id},name=${item.name}`}
                        className="inline-block p-1 text-gray-400 transition-colors duration-150 hover:text-ebony"
                      >
                        <XIcon
                          className="h-4 w-4"
                          onClick={() => {
                            dispatch(removeProduct(item.id));
                          }}
                        />
                      </Link>
                    </div>
                  ))
                ) : (
                  <div>There are no products in your Enquiry List!</div>
                )}
              </div>
              {enquiryList.length > 0 && (
                <div className="flex gap-x-4 mt-10 px-8 justify-end">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(removeAll());
                    }}
                    className="btn-secondary"
                  >
                    Clear All
                  </div>
                  <Link
                    to="/contact"
                    className="btn-primary"
                    onClick={() => {
                      setIsOpenEnq(false);
                    }}
                  >
                    Get a Quote
                  </Link>
                </div>
              )}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={isOpenNav} as={Fragment}>
        <Dialog as="div" className="ag-nav__menu" onClose={setIsOpenNav}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="ag-nav__menu__overlay" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-200 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-200 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="ag-nav__menu__content">
              <nav className="grid">
                <Link
                  to="/"
                  onClick={() => {
                    setIsOpenNav(false);
                  }}
                  className="flex justify-between items-center w-full py-3 text-lg font-medium tracking-tight text-gray-800"
                >
                  Home
                </Link>

                <Link
                  onClick={() => {
                    setIsOpenNav(false);
                  }}
                  to="/products"
                  className="flex justify-between items-center w-full py-3 text-lg font-medium tracking-tight text-gray-800"
                >
                  Products
                </Link>

                <Link
                  onClick={() => {
                    setIsOpenNav(false);
                  }}
                  to="/about"
                  className="flex justify-between items-center w-full py-3 text-lg font-medium tracking-tight text-gray-800"
                >
                  About
                </Link>

                <Link
                  to="/contact"
                  onClick={() => {
                    setIsOpenNav(false);
                  }}
                  className="flex justify-between items-center w-full py-3 text-lg font-medium tracking-tight text-gray-800"
                >
                  Contact
                </Link>
              </nav>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header
        className={`ag-header fixed w-full ${
          location.pathname === "/"
            ? headerRef.current && y > headerRef.current.clientHeight / 2
              ? "bg-white border-solid border-b border-gray-200"
              : "bg-offwhite"
            : "bg-white border-solid border-b border-gray-200"
        }`}
        ref={headerRef}
      >
        <>
          <div className="px-5 lg:px-6 container ">
            <div className="relative flex items-stretch justify-between h-16 lg:h-24">
              {/* Mobile menu button */}
              <div className="inset-y-0 left-0 flex items-center lg:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-lg -ml-2 p-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                  onClick={() => setIsOpenNav(!isOpenNav)}
                >
                  <span className="sr-only">Open main menu</span>
                  {isOpenNav ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>

              {/* logo */}
              <div className="flex-shrink-0 flex items-center">
                <Link
                  to="/"
                  onClick={() => {
                    setSelectedNav("home");
                  }}
                >
                  <img
                    className="h-13 w-auto hidden lg:block"
                    src={Logo}
                    alt="Agni Logo"
                  />
                  <img
                    className="h-13 w-auto lg:hidden"
                    src={Logo}
                    alt="Agni Logo"
                  />
                </Link>
              </div>

              {/* Navigation */}
              <nav className="hidden lg:flex md:ml-14">
                <Link
                  to="/"
                  onClick={() => {
                    setSelectedNav("home");
                  }}
                  className={`text-gray-800 ag-nav__item ${
                    selectedNav === "home" ? "ag-nav__item--active" : ""
                  }`}
                >
                  Home
                </Link>
                <Link
                  to="/products"
                  onClick={() => {
                    setSelectedNav("products");
                  }}
                  className={`text-gray-800 ag-nav__item ${
                    selectedNav === "products" ? "ag-nav__item--active" : ""
                  }`}
                >
                  Products
                </Link>

                <Link
                  to="/about"
                  onClick={() => {
                    setSelectedNav("about");
                  }}
                  className={`text-gray-800 ag-nav__item ${
                    selectedNav === "about" ? "ag-nav__item--active" : ""
                  }`}
                >
                  About
                </Link>

                <Link
                  to="/contact"
                  onClick={() => {
                    setSelectedNav("contact");
                  }}
                  className={`text-gray-800 ag-nav__item ${
                    selectedNav === "contact" ? "ag-nav__item--active" : ""
                  }`}
                >
                  Contact
                </Link>
              </nav>
              <div className="flex gap-x-8 items-center justify-end">
                <button
                  type="button"
                  className="text-sm flex item font-medium text-gray-800 tracking-tight hover:text-gray-600"
                  onClick={() => setIsOpenEnq(true)}
                >
                  <AnnotationIcon className="h-6 w-6 lg:h-5 lg:w-5 mr-1" />
                  <span className="mr-1 hidden md:block">My Enquiries</span>
                  <span>({enquiryList.length})</span>
                </button>
              </div>
            </div>
          </div>

          {/* <Disclosure.Panel className="ag-nav__menu">
              <nav className="grid">
                <a
                  to="#"
                  className="flex justify-between items-center w-full py-3 text-lg font-medium tracking-tight text-gray-800"
                >
                  Home
                </a>

                <a
                  to="#"
                  className="flex justify-between items-center w-full py-3 text-lg font-medium tracking-tight text-gray-800"
                >
                  About us
                </a>

                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between items-center w-full py-3 text-lg font-medium tracking-tight text-gray-800">
                        <span>Products</span>
                        <ChevronDownIcon
                          className={`w-5 h-5 transform ${
                            open ? 'rotate-180' : ''
                          }`}
                        />
                      </Disclosure.Button>

                      <Disclosure.Panel className="my-4 border-l border-solid border-gray-200 pl-8">
                        {products.map((item) => (
                          <a
                            key={item.name}
                            to={item.to}
                            className="py-3 block text-gray-800 text-base"
                          >
                            {item.name}
                          </a>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                <a
                  to="#"
                  className="flex justify-between items-center w-full py-3 text-lg font-medium tracking-tight text-gray-800"
                >
                  Contact us
                </a>
              </nav>
            </Disclosure.Panel> */}
        </>
      </header>
    </>
  );
};
export default Header;
