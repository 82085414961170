import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  useEffect(() => {
    document.title = "Agni | Not found";
  }, []);

  return (
    <div>
      <div className="h-screen grid place-content-center">
        <div className="text-center">
          <h1 className="text-primary text-9xl font-medium">404</h1>
          <h2 className="ag-heading--two mt-4">This page is not available</h2>
          <Link className="btn-primary mt-12 inline-block" to="/">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
