import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SectionHeader from '../components/sectionHeader';
import TruckIcon from '../assets/services_icon/truck.svg';
import ConnectIcon from '../assets/services_icon/connect.svg';
import DownloadCloudIcon from '../assets/services_icon/downloadCloud.svg';
import SupportIcon from '../assets/services_icon/support.svg';
import ConsultationIcon from '../assets/services_icon/consultation.svg';
import TrainIcon from '../assets/services_icon/training.svg';
import MiscIcon from '../assets/services_icon/misc.svg';
import { useGetCategoriesQuery } from '../services/categoryApi';
import BrandList from '../components/brandList';

const services = [
  {
    name: 'Import',
    description:
      'We offers products related to Fire Safety and Security, Assurance and Survelliance systems and other industrial products.',
    icon: TruckIcon,
  },
  {
    name: 'Distribution',
    description:
      'Our in-site warehouses and network of like-minded companies ensure a smooth delivery of products.',
    icon: ConnectIcon,
  },
  {
    name: 'Installation',
    description:
      'Our industry trained and certified professionals make sure you get a detailed and thorough installation of your systems.',
    icon: DownloadCloudIcon,
  },
  {
    name: 'Maintenance',
    description:
      'We provide a detailed inspection and support, warranties and remote and in-site maintenance of all services.',
    icon: SupportIcon,
  },
  {
    name: 'Consultation',
    description:
      'Our in-site warehouses and network of like-minded companies ensure a smooth delivery of products.',
    icon: ConsultationIcon,
  },
  {
    name: 'Training',
    description:
      'Our industry trained and certified professionals make sure you get a detailed and thorough installation of your systems.',
    icon: TrainIcon,
  },
  {
    name: 'Miscellaneous',
    description:
      'We provide a detailed inspection and support, warranties and remote and in-site maintenance of all services.',
    icon: MiscIcon,
  },
];

const whyUs = [
  {
    name: 'One Stop Solution',
    description:
      'We offer a wide range of products related to Fire Safety and Security, Assurance and Surveillance (CCTV) systems, Time Attendance Systems, Alarm Systems and other Official and Industrial Products. ',
  },
  {
    name: 'International Standards',
    description:
      'All of our products are ensured to follow some sort of international standards. From various ISO, ISI Standards to country specific standards, we select products that meet proper certifications.',
  },
  {
    name: 'Experienced Services',
    description:
      'With more than 10 years of experience in this field, our staffs are trained professionals. From consultation to sales, to in site installation and maintenance we do it all in house with our trained and cordial staffs.',
  },
];

const Home = () => {
  const { data } = useGetCategoriesQuery();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (data) setCategories(data.slice(0, 4));
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-16 lg:pt-24">
      <section className="ag-hero ag-section gap-y-10 lg:gap-y-0">
        <div className="container grid lg:grid-cols-2">
          <div className="ag-hero__content">
            <h1 className="ag-hero__heading">
              Your <span className="text-primary">Safety</span>.&nbsp;
              <br className="hidden md:block" />
              Our Responsibility.
            </h1>

            <p className="ag-text mx-auto mt-5 lg:mx-0 lg:mt-6 text-darkGray ag-hero__subtext">
              We provide a wide range of products and services for your safety
              and security needs.
              {/* We plant to build an assured Nepal by allowing
              us to provide the safety and security you deserve. */}
            </p>

            <div className="flex justify-center flex-wrap gap-y-4 mt-6 lg:justify-start lg:mt-8 items-center gap-x-3 lg:gap-x-5">
              <div>
                <Link to="/contact" className="btn-primary inline-block">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <figure className="ag-hero__image-container">
          <img
            className="ag-hero__image"
            src={require('../assets/products.png').default}
            alt="hero"
          />
        </figure>
      </section>

      {/*
       * Why Us
       */}
      <section className="ag-section">
        <div className="container grid md:grid-cols-12 gap-x-5">
          <div className="md:col-start-2 md:col-span-10 lg:col-span-12">
            <SectionHeader
              header="WHY US"
              subHeader="We are your go to solution for everything related to safety and
            security."
            />

            <div className="grid gap-y-12 lg:grid-cols-7 lg:gap-x-16">
              {whyUs.map((item) => (
                <div className="lg:col-span-2" key={item.name}>
                  <h3 className="ag-points-title text-primary font-semibold">
                    {item.name}
                  </h3>
                  <p className="ag-text text-stormGray mt-6">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/*
       * Products
       */}
      <section className="ag-section--bg bg-offwhite">
        <div className="container grid md:grid-cols-12 md:gap-x-5">
          <div className="md:col-start-2 md:col-span-10 lg:col-span-12">
            <SectionHeader
              header="OUR PRODUCTS"
              subHeader="We provide a wide range of products 
            for your safety and security needs."
            />
          </div>

          <div className="md:col-start-2 md:col-span-10 lg:col-span-12">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-8 md:gap-x-8 md:gap-y-10 lg:gap-x-10 lg:gap-y-10">
              {categories.map((item) => (
                <div className="col-span-1" key={item.id}>
                  <div className="ag-card">
                    <Link
                      to={`products/${item.id},${item.name}`}
                      className="ag-card__link"
                    >
                      <figure className="ag-card__image-container">
                        <img
                          src={'https://api.eversafe.com.np' + item.image.url}
                          alt={item.image.caption}
                          className="ag-card__image"
                        />
                      </figure>
                      <div className="ag-card__body">
                        <h3 className="ag-card__title">{item.name}</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="md:col-span-12 mt-16 flex justify-center">
            <Link
              to="/products"
              className="btn-primary--small lg:btn-primary inline-block"
            >
              View All Products
            </Link>
          </div>
        </div>
      </section>

      {/*
       * Services
       */}
      <section className="ag-section">
        <div className="container grid md:grid-cols-12">
          <div className="md:col-start-2 md:col-span-10 lg:col-span-12">
            <SectionHeader
              header="OUR SERVICES"
              subHeader="Our Services include, but is not limited to."
            />
          </div>

          <div className="md:col-start-2 md:col-span-10 lg:col-span-12 lg:mt-12">
            <div className="grid grid-cols-4 gap-x-6 gap-y-8 lg:gap-x-10 lg:gap-y-12">
              {services.map((item) => (
                <div
                  className="col-span-4 md:col-span-2 lg:col-span-1"
                  key={item.name}
                >
                  <div className="ag-card">
                    <figure className="ag-card__icon-container">
                      <img
                        src={item.icon}
                        alt={item.name}
                        className="ag-card__icon"
                      />
                    </figure>
                    <div className="ag-card__body">
                      <h3 className="ag-card__title">{item.name}</h3>
                      <p className="ag-card__description">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/*
       * Who are we
       */}
      <section className="ag-section--bg bg-offwhite">
        <div className="container grid gap-y-10 items-center md:grid-cols-12 md:gap-x-5 lg:grid-cols-2 lg:gap-x-20">
          <figure className="md:col-start-2 md:col-span-10 lg:col-span-1 ag-figure--1-1">
            <img
              src={require('../assets/emp.jpg').default}
              alt="employee holding fire extinguisher"
            />
          </figure>

          <div className="md:col-start-2 md:col-span-10 lg:col-span-1">
            <SectionHeader
              header="WHO ARE WE"
              subHeader="Leading Importer and Distributors of Fire Fighting Systems in Nepal"
              full
              spaceSmall
            />
            <p className="ag-text--lg text-stormGray">
              Leading and established importers and distributors of Fire
              Fighting Systems in Nepal. We are your go to solution for
              everything related to safety and security.We also excel in other
              Safety and Security, Official as well as Industrial products and
              services.
            </p>
            <Link to="/about" className="btn-primary inline-block mt-12">
              Learn More
            </Link>
          </div>
        </div>
      </section>

      <BrandList />
    </div>
  );
};
export default Home;
