import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RelatedProducts = ({ relProd }) => {
  const [relatedProduct, setRelatedProduct] = useState([]);

  useEffect(() => {
    if (relProd) setRelatedProduct(relProd);
  }, [relProd]);

  return relatedProduct.length ? (
    <section className="ag-section container grid gap-y-8 grid-cols-2 gap-x-6 md:gap-x-10 md:gap-y-10 lg:grid-cols-4 lg:gap-x-7">
      {/*
       * Related Product
       */}
      <div className="col-span-2 lg:col-span-4">
        <h3 className="ag-title--small md:mb-0 lg:mb-12">Related Products</h3>
      </div>

      {relatedProduct.map((item) => (
        <div className="ag-card--product" key={item.id}>
          <Link
            to={`/product/id=${item.id},name=${item.name}`}
            className="ag-card__link"
          >
            <figure className="ag-card__image-container">
              <img
                className="ag-card__image"
                src={"https://api.eversafe.com.np" + item.image.url}
                alt={item.name}
              />
            </figure>

            <div className="ag-card__body">
              <h3 className="ag-card__title">{item.name}</h3>
            </div>
          </Link>
        </div>
      ))}
    </section>
  ) : (
    <></>
  );
};

export default RelatedProducts;
