import React from 'react';

const SectionHeader = (props) => {
  return (
    <>
      <h2 className="ag-title--small mb-4 md:mb-6">{props.header}</h2>
      <h3
        className={`ag-heading--two ${
          props.full ? '' : 'w-full md:w-5/6 lg:w-3/5'
        } ${props.spaceSmall ? 'mb-6' : 'mb-10 md:mb-16'}`}
      >
        {props.subHeader}
      </h3>
    </>
  );
};

export default SectionHeader;
