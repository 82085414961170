import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductList from "../components/productList";
import { useGetCategoriesQuery } from "../services/categoryApi";
import SectionHeader from "../components/sectionHeader";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

const Products = () => {
  const { id } = useParams();
  const [categoryId, setCategoryId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const { data } = useGetCategoriesQuery();
  const [sideNav, setSideNav] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      setSideNav(data);
      const index = data.findIndex((cat) => cat.id.toString() === categoryId);
      if (index >= 0) {
        setSelectedCategory(data[index]);
      } else {
        setSelectedCategory(data[0]);
      }
    }
  }, [data, categoryId]);

  useEffect(() => {
    if (id) {
      const mySubString = id.substring(
        id.indexOf("=") + 1,
        id.lastIndexOf(",")
      );
      setCategoryId(mySubString);
    } else {
      setCategoryId("");
    }
  }, [id]);

  return (
    <div className="pt-16 lg:pt-24">
      {/*
       * Breadcrumbs
       */}
      {/* <nav className="container">
        <ul className="ag-breadcrumbs">
          <li className="ag-breadcrumbs__item">
            <div className="ag-breadcrumbs__link">
              Products
            </div>
          </li>
          <li className="ag-breadcrumbs__item">
            <div
              // href="#"
              className="ag-breadcrumbs__link ag-breadcrumbs__link--active"
            >
                {selectedCategory != null ? selectedCategory.name :<div></div>}
            </div>
          </li>
        </ul>
      </nav> */}

      <section className="ag-section container mt-10 md:mt-16 lg:mt-28">
        <div>
          <SectionHeader
            header="PRODUCTS"
            subHeader="We provide a wide range of products 
            for your safety and security needs."
          />
        </div>

        {/*
         * Side nav
         */}

        <Swiper
          className="ag-nav-slider"
          spaceBetween={32}
          slidesPerView="auto"
          grabCursor
          slidesOffsetAfter={48}
          breakpoints={{
            768: { spaceBetween: 48, slidesOffsetAfter: 80 },
          }}
        >
          {/* ------------ add all products category ------------ */}
          {[{ id: "", name: "All Products", showAll: true }, ...sideNav].map(
            (item) => (
              <SwiperSlide
                style={{ cursor: "pointer" }}
                // href={}
                onClick={() => {
                  setCategoryId(item.id.toString());
                  const index = data.findIndex(
                    (cat) => cat.id.toString() === item.id.toString()
                  );
                  if (item.showAll) {
                    setSelectedCategory("all");
                  } else if (index >= 0) {
                    setSelectedCategory(data[index]);
                  } else {
                    setSelectedCategory(data[0]);
                  }
                  window.history.pushState(
                    {},
                    null,
                    `/products${
                      item.id === "" ? "" : `/id=${item.id},name=${item.name}`
                    }`
                  );
                }}
                className={`ag-sidenav__link whitespace-nowrap ${
                  item.id.toString() === categoryId
                    ? "ag-sidenav__link--active"
                    : ""
                }`}
                key={item.id}
              >
                {item.name}
              </SwiperSlide>
            )
          )}
        </Swiper>

        {/*
         * Product list
         */}
        {selectedCategory === "all" ? (
          <ProductList id={null} />
        ) : (
          <ProductList id={categoryId} />
        )}
      </section>
    </div>
  );
};
export default Products;
