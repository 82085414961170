import { useState, useEffect } from "react";
import { useGetBrandsQuery } from "../services/productsApi";
import SectionHeader from "../components/sectionHeader";

// Import Swiper React components
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles
import "swiper/swiper.min.css";

const BrandList = () => {
  const { data } = useGetBrandsQuery();
  const [brandList, setBrandList] = useState([]);
  const [containerGap, setContainerGap] = useState();
  const size = useWindowSize();

  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      }

      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  useEffect(() => {
    function calcContainerGap() {
      const containerWidth =
        document.querySelectorAll(".container")[1].offsetWidth;
      let padding = 20;

      if (size.width >= 640) padding = 32;

      const gap = (size.width - containerWidth) / 2 + padding;
      setContainerGap(gap);
    }

    calcContainerGap();
  }, [size]);

  useEffect(() => {
    if (data)
      setBrandList(data.filter((item) => item.name !== "Agni Enterprises"));
  }, [data]);

  return (
    <>
      <section className="ag-section">
        <div className="container grid md:grid-cols-12">
          <div className="md:col-start-2 md:col-span-10 lg:col-span-12">
            <SectionHeader
              header="BRANDS"
              subHeader="We are ecstatic to work with such reputed brands"
            />
          </div>
        </div>

        <Swiper
          modules={[Navigation]}
          className="ag-brand-slider"
          spaceBetween={64}
          slidesPerView="auto"
          grabCursor
          observer
          observeParents
          allowSlideNext
          allowSlidePrev
          allowTouchMove
          enabled
          hidden={false}
          updateOnWindowResize
          navigation={{
            nextEl: ".ag-brand-slider__next",
            prevEl: ".ag-brand-slider__prev",
          }}
          slidesOffsetBefore={containerGap}
          slidesOffsetAfter={containerGap}
        >
          {brandList.map((item) => (
            <SwiperSlide className="flex items-center" key={item.name}>
              <img
                src={"https://api.eversafe.com.np" + item.logo.url}
                alt={item.name}
                className="ag-brand-logo"
              />
            </SwiperSlide>
          ))}
          <div className="container flex justify-end items-center mt-8 md:mt-12 md:gap-x-5 lg:mt-16">
            <button className="ag-brand-slider__prev">
              <span className="sr-only">Previous</span>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20.9999 11H6.41394L11.7069 5.70697L10.2929 4.29297L2.58594 12L10.2929 19.707L11.7069 18.293L6.41394 13H20.9999V11Z" />
              </svg>
            </button>
            <button className="ag-brand-slider__next">
              <span className="sr-only">Next</span>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.586 11H17.172L11.879 5.70697L13.293 4.29297L21 12L13.293 19.707L11.879 18.293L17.172 13H2.586V11Z" />
              </svg>
            </button>
          </div>
        </Swiper>
      </section>
    </>
  );
};

export default BrandList;
