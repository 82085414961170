import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const createRequest = (url) => ({ url });

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    getProduct: builder.query({
      query: (productId) => createRequest(`/products/${productId}`),
    }),
    getProducts: builder.query({
      query: () => createRequest("/products"),
    }),
    getRelatedProduct: builder.query({
      query: (productId) => createRequest(`/products/related/${productId}`),
    }),
    getCategoryProducts: builder.query({
      query: (categoryId) =>
        createRequest(`/categories/products/${categoryId}`),
    }),
    getBrands: builder.query({
      query: () => createRequest(`/brands`),
    }),
    submitData: builder.mutation({
      query: ({ ...data }) => ({
        url: "/enquiries",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useGetRelatedProductQuery,
  useGetBrandsQuery,
  useSubmitDataMutation,
  useGetCategoryProductsQuery,
} = productsApi;
