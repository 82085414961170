export const validateEmail = (email) => {
  let regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!regEmail.test(email)) {
    return false;
  }
};

export const validatePhone = (phone) => {
  let regPhone =
    /^((\+)?977(\s|-|))?((98(0|1|2)[0-9]{7})|(98(4|6)[0-9]{7})|(985[0-9]{7})|(97(4|5)[0-9]{7})|(9(88|(6(1|2)))[0-9]{7})|(972[0-9]{7}))$/;
  let regLandine = /^0?(1|[1-9][0-9])(-|\s)?(4|5|6)([0-9]){5,6}$/;
  if (!regPhone.test(phone) && !regLandine.test(phone)) {
    return false;
  }
};

export const validateName = (name) => {
  let regName = /^(?!\s+$)[A-Za-z\s]+$/;
  if (!regName.test(name)) {
    return false;
  }
};
