import { useState, useEffect } from "react";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Link } from "react-router-dom";
import {
  useGetCategoryProductsQuery,
  useGetProductsQuery,
} from "../services/productsApi";

const LoadingSkeleton = () => (
  <div className="ag-card--product">
    <RectShape color="#E0E0E0" style={{ paddingTop: "110%" }} />
  </div>
);

const ProductList = ({ id }) => {
  const { data: allData, isLoading: allDataLoading } = useGetProductsQuery();
  const [relatedProduct, setRelatedProduct] = useState(allData);
  const { data, isLoading } = useGetCategoryProductsQuery(id);

  useEffect(() => {
    if (!id) setRelatedProduct(allData);
    else if (data) setRelatedProduct(data);
  }, [data, allData, id]);

  if (isLoading || allDataLoading)
    return (
      <div className="grid grid-cols-2 mt-10 gap-x-6 gap-y-8 md:mt-16 md:gap-x-8 md:gap-y-10 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-10 lg:gap-y-12">
        <LoadingSkeleton />
        <LoadingSkeleton />
        <LoadingSkeleton />
        <LoadingSkeleton />
      </div>
    );

  return (
    <div className="grid grid-cols-2 mt-10 gap-x-6 gap-y-8 md:mt-16 md:gap-x-8 md:gap-y-10 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-10 lg:gap-y-12">
      {relatedProduct &&
        relatedProduct.map((item) => (
          <div className="ag-card--product" key={item.id}>
            <Link
              to={`/product/id=${item.id},name=${item.name}`}
              className="ag-card__link"
            >
              <figure className="ag-card__image-container">
                <img
                  className="ag-card__image"
                  src={"https://api.eversafe.com.np" + item.image.url}
                  alt={item.image.caption}
                />
              </figure>

              <div className="ag-card__body">
                <h3 className="ag-card__title">{item.name}</h3>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default ProductList;
