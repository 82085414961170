import { useEffect } from "react";
import SectionHeader from "../components/sectionHeader";

const values = [
  {
    name: "Customer Satifaction",
    description:
      "Customer experience is a model for a good business and our first priority.",
  },
  {
    name: "Growth & Development",
    description: "We strive for growth and development in all aspects, always.",
  },
  {
    name: "Quality Products",
    description: "All of our products strive to match recognized market standards.",
  },
];

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-16 lg:pt-24">
      {/*
       * About us
       */}
      <section className="ag-section--small">
        <div className="ag-about__hero">
          <div className="md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <h2 className="ag-title--small mb-4 md:mb-6">OUR STORY</h2>
            <h3 className="ag-heading--two">
              Leading Importer and Distributors of Fire Fighting Systems in
              Nepal
            </h3>
          </div>
        </div>
      </section>

      {/*
       * Team Image
       */}
      <section className="ag-section--small container">
        <figure className="ag-about__team-image-container">
          <img
            src="https://images.pexels.com/photos/1216589/pexels-photo-1216589.jpeg"
            alt="team"
            className="ag-about__team-image"
          />
        </figure>
      </section>

      {/*
       * About us details
       */}
      <section className="ag-section">
        <div className="container grid md:gap-x-5 md:grid-cols-12">
          <div className="ag-about__details md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <p className="ag-text--lg text-darkGray">
              Ever since its establishment in 2010, Agni Enterprises has grown
              from a modest enthusiastic team into an industry leader in Nepal
              for all things safety and security related. With new services and
              products catering to office and industry needs, Agni Enterprises
              has also grown as a holistic solution with range of products, and
              trained professionals to serve all over Nepal.
            </p>
            <p className="ag-text--xl">
              Agni Enterprises has been dealing its customers with innovative
              &amp; high-quality products.
            </p>
            <p className="ag-text--lg text-darkGray">
              We offer a wide range of products including fire extinguisher,
              CCTV survelliance system, walk-through and handheld metal
              detector, access control &amp; time attendance system, note
              counting machine, earthquake alarm, fire hydrant system, sprinkler
              system, safety signage system and many mre. Our products are
              Malaysian Standard, ISI, ISO, CE, NS, NFPA Standard / Certified
              products. Agni Enterprises has been serving for its products event
              after sales services and provide regular training to the
              customers.
            </p>
          </div>
        </div>
      </section>

      {/*
       * Vision
       */}
      <section className="ag-section--bg bg-offwhite">
        <div className="container grid md:gap-x-5 md:grid-cols-12">
          <div className="md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <SectionHeader
              header="OUR VISION"
              subHeader="Our vision is to build a self sufficient safety and security industry in Nepal."
              full
              spaceSmall
            />
            <p className="ag-text--lg text-darkGray">
              With not much progress in safety and security field here, we are
              partnering with internationally recognized communities and like
              minded businesses to create a sustainable industry; all for a
              secure Nepal. We don’t want to compromise in terms of safety of
              you.
            </p>
          </div>
        </div>
      </section>

      {/*
       * Our Values
       */}
      <section className="ag-section">
        <div className="container grid md:gap-x-5 md:grid-cols-12">
          <div className="md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <SectionHeader
              header="OUR VALUES"
              subHeader="We are your go to solution for everything
              related to safety and security."
              full
            />

            <div className="grid gap-y-12 lg:grid-cols-3 lg:gap-x-14">
              {values.map((item) => (
                <div className="" key={item.name}>
                  <h3 className="ag-points-title text-primary font-semibold">
                    {item.name}
                  </h3>
                  <p className="ag-text text-darkGray mt-6">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default About;
