// import logo from "./logo.svg";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import "./sass/main.scss";
import Header from "./components/header";
import Footer from "./components/footer";
import About from "./pages/about";
import Home from "./pages/home";
import Products from "./pages/products";
import ProductDetails from "./pages/productDetails";
import Contact from "./pages/contact";
import NotFound from "./pages/notFound";
import store from "./store";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Provider store={store}>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/products/:id" component={Products} />
            <Route path="/products" component={Products} />
            <Route path="/product/:id" component={ProductDetails} />
            <Route path="/*" component={NotFound} />
          </Switch>
          <Footer />
        </Provider>
      </BrowserRouter>
    </div>
  );
};

export default App;
